import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEOBlog from '../components/seo-blog'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEOBlog title={post.frontmatter.title} description={post.excerpt} />
        <div style={{ textAlign: `center` }}>
          <h1>{post.frontmatter.title}</h1>
          <p style={{ display: `block`, textTransform: `uppercase` }}>
            {post.frontmatter.date} | {post.frontmatter.category}
          </p>
        </div>

        <hr/>

        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="anthony-post" dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </div>

        {/*<ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>*/}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        category
      }
    }
  }
`
